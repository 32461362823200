import React, { useState } from "react";

const IndividualCard = ({ imageSrc, name, minted, price }) => {
  const [quantity, setQuantity] = useState(1);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div>
        <img src={imageSrc} alt="" />

        <div className="flex flex-col gap-2 bg-black-lighter p-2 sm:p-4">
          <h1 className="md:text-[17px] font-bold md:flex-1">{name}</h1>

          <div>
            <p className="md:text-lg font-bold">Minted: {minted}/50</p>

            <p className="md:text-lg font-bold">
              Price: <span className="text-blue">{price} USDC</span>
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-x-2">
        <button
          className="bg-red text-[17px] font-bold py-3 rounded-lg"
          onClick={handleDecrement}
        >
          -
        </button>
        <div className="col-span-2 text-[17px] font-bold bg-white py-3 rounded-lg text-black text-center">
          {quantity}
        </div>
        <button
          className="bg-red text-[17px] font-bold py-3 rounded-lg"
          onClick={handleIncrement}
        >
          +
        </button>
      </div>

      <button className="bg-red py-3 text-[17px] rounded-lg font-bold">
        Mint
      </button>
    </div>
  );
};

export default IndividualCard;
