import { useAtom } from "jotai";
import React from "react";
import { BsListUl } from "react-icons/bs";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { SiGitbook } from "react-icons/si";
import { TfiLayoutGrid4Alt } from "react-icons/tfi";
import Whelp1 from "../assets/Whelps/1.jpg";
import Whelp10 from "../assets/Whelps/10.jpg";
import Whelp11 from "../assets/Whelps/11.jpg";
import Whelp12 from "../assets/Whelps/12.jpg";
import Whelp2 from "../assets/Whelps/2.jpg";
import Whelp3 from "../assets/Whelps/3.jpg";
import Whelp4 from "../assets/Whelps/4.jpg";
import Whelp5 from "../assets/Whelps/5.jpg";
import Whelp6 from "../assets/Whelps/6.jpg";
import Whelp7 from "../assets/Whelps/7.jpg";
import Whelp8 from "../assets/Whelps/8.jpg";
import Whelp9 from "../assets/Whelps/9.jpg";
import CharacterCard from "../components/CharacterCard";
import Header from "../components/Header";
import { isLayoutGridAtom } from "../pages/MyNFTs";

const WhelpDragons = () => {
  const [isLayoutGrid, setIsLayoutGrid] = useAtom(isLayoutGridAtom);

  return (
    <div className="min-h-screen bg-fireBg bg-no-repeat bg-bottom relative p-4 lg:p-8 xl:py-8 xl:px-14 2xl:px-20 2xl:py-12">
      <Header />

      <div className="text-white px-1 pt-4 lg:pt-10">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold text-center lg:text-4xl">
              Welcome to Whelp Dragons NEST!
            </h1>

            <div className="text-center flex flex-col gap-2">
              <p>
                Here you can keep track of all the NFTs you own, whether it's a
                single NFT or a series of NFTs.
              </p>

              <p>
                There are 12 unique Whelp Dragon NFTs with their unique stories
                and effects.
              </p>

              <p>
                Collect a complete set of 12 Whelp Dragons to Unlock a Dragon
                Slayer NFT.
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-center gap-6 sm:flex-row sm:gap-8">
            <button className="hover:underline underline-offset-[6px]">
              {"<"} Go back to Main Inventory page
            </button>

            <button className="hover:underline underline-offset-[6px]">
              Whelp Dragons Utility {">"}
            </button>
          </div>
        </div>

        <div className="border border-red p-5 mt-10 flex flex-col gap-6">
          <div className="flex items-center justify-between gap-3">
            <h1 className="text-blue font-dragonslapper sm:text-xl">
              SLAYER CHARACTER CARDS
            </h1>

            <div className="flex">
              <button
                className={` px-4 py-1.5 ${
                  isLayoutGrid ? "bg-black-lighter" : "bg-[#1a1b1fff]"
                }`}
                onClick={() => {
                  if (isLayoutGrid) {
                    setIsLayoutGrid(false);
                  }
                }}
              >
                <BsListUl className="w-6 h-6" />
              </button>

              <button
                className={`px-4 py-1.5 ${
                  isLayoutGrid ? "bg-[#1a1b1fff]" : "bg-black-lighter"
                }`}
                onClick={() => {
                  if (!isLayoutGrid) {
                    setIsLayoutGrid(true);
                  }
                }}
              >
                <TfiLayoutGrid4Alt className="w-[1.3rem] h-[1.3rem]" />
              </button>
            </div>
          </div>

          <div
            className={`grid ${
              isLayoutGrid
                ? "grid-cols-1 gap-4 vsm:grid-cols-2 md:grid-cols-3 md:gap-6 lg:grid-cols-4 2xl:grid-cols-5"
                : "grid-cols-1 gap-4"
            }`}
          >
            <CharacterCard imageSrc={Whelp1} name="Ikiryo" ownedAmount={0} />

            <CharacterCard imageSrc={Whelp2} name="Raza" ownedAmount={0} />

            <CharacterCard imageSrc={Whelp3} name="Bakari" ownedAmount={0} />

            <CharacterCard imageSrc={Whelp4} name="Radka" ownedAmount={0} />

            <CharacterCard imageSrc={Whelp5} name="Galen" ownedAmount={0} />

            <CharacterCard imageSrc={Whelp6} name="Kazuki" ownedAmount={0} />

            <CharacterCard imageSrc={Whelp7} name="Boman" ownedAmount={0} />

            <CharacterCard imageSrc={Whelp8} name="Issar" ownedAmount={0} />

            <CharacterCard imageSrc={Whelp9} name="Rafiki" ownedAmount={0} />

            <CharacterCard imageSrc={Whelp10} name="Gunter" ownedAmount={0} />

            <CharacterCard
              imageSrc={Whelp11}
              name="Nightwing"
              ownedAmount={0}
            />

            <CharacterCard imageSrc={Whelp12} name="Zoeb" ownedAmount={0} />
          </div>
        </div>

        <div className="relative flex flex-col gap-8">
          <div className="z-30 w-full h-[2px] bg-red mt-5 lg:mt-10"></div>

          <div className="z-30 flex flex-col gap-2 items-center text-white mb-5">
            <p className="font-bold lg:text-lg">
              War Haven 2023 ©️ All rights reserved.
            </p>

            <div className="flex items-center justify-center gap-4">
              <FaDiscord className="w-8 h-8 cursor-pointer" />
              <SiGitbook className="w-8 h-8 cursor-pointer" />
              <FaTwitter className="w-8 h-8 cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhelpDragons;
