import { atom, useAtom } from "jotai";
import React, { useEffect } from "react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { SiGitbook } from "react-icons/si";
import { Link } from "react-router-dom";
import BackgroundImage from "../assets/Background_Image.png";
import DragonKey from "../assets/Dragon Key.png";
import DragonVault from "../assets/Dragon Vault.jpg";
import DragonSlayer from "../assets/Dragonslayer.jpg";
import SlayerCards from "../assets/Slayer cards image.png";
import WhelpDrangons from "../assets/Whelp dragon image.png";
import DragonSlayerModal from "../components/DragonSlayerModal";
import FAQ from "../components/FAQ";
import Header from "../components/Header";

export const isDragonSlayerModalOpenAtom = atom(false);
export const isLayoutGridAtom = atom(false);

const MyNFTs = () => {
  const [isDragonSlayerModalOpen, setIsDragonSlayerModalOpen] = useAtom(
    isDragonSlayerModalOpenAtom
  );

  useEffect(() => {
    if (isDragonSlayerModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isDragonSlayerModalOpen]);

  return (
    <div
      className={`relative p-4 lg:p-8 xl:py-8 xl:px-14 2xl:px-20 2xl:py-12 ${
        isDragonSlayerModalOpen ? "" : ""
      }`}
    >
      <Header />

      <div className="text-white px-1 pt-4 lg:pt-10">
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl font-bold text-center lg:text-4xl">
            Welcome to your War Haven Inventory
          </h1>

          <p className="text-center sm:text-start">
            Here you can keep track of all the NFTs you own, whether it's a
            single NFT or a series of NFTs. For series NFTs, you can open the
            collection to see all the NFTs it contains.
          </p>
        </div>

        <div className="mt-8 flex flex-col gap-8">
          <Link to="/whelp-dragons">
            <div className="relative border border-red p-4 flex flex-col gap-6 lg:flex-row lg:gap-10 lg:p-8 lg:text-lg">
              <img
                src={WhelpDrangons}
                alt=""
                className="w-full max-w-[25rem] self-center lg:max-w-[21rem] xl:max-w-[19rem]"
              />

              <div className="flex flex-col gap-5">
                <h1 className="font-dragonslapper text-center text-blue text-2xl font-bold lg:text-start">
                  WHELP DRAGONS
                </h1>

                <p className="text-lg font-bold">Unlimited Supply</p>

                <div className="lg:leading-8">
                  <p>
                    There are 12 unique Whelp Dragon NFTs with their unique
                    stories and effects.
                  </p>
                  <p>
                    Collect a complete set of 12 Whelp Dragons to Unlock a
                    Dragon Slayer NFT.
                  </p>
                </div>

                <div className="flex flex-col gap-5 items-start xl:flex-row xl:gap-6">
                  <button
                    className="hover:underline underline-offset-[6px]"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Enter your Whelp Dragons Nest {">"}
                  </button>
                  <button
                    className="hover:underline underline-offset-[6px]"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Whelp Dragons Utility {">"}
                  </button>
                </div>
              </div>
            </div>
          </Link>

          <div className="relative border border-red p-4 flex flex-col gap-6 lg:flex-row lg:gap-10 lg:p-8 lg:text-lg">
            <img
              src={DragonSlayer}
              alt=""
              className="w-full max-w-[25rem] self-center lg:max-w-[21rem] xl:max-w-[19rem]"
            />

            <div className="flex flex-col gap-5">
              <h1 className="font-dragonslapper text-center text-blue text-2xl font-bold lg:text-start">
                DRAGON SLAYER
              </h1>

              <div className="flex flex-col vsm:flex-row vsm:gap-6 sm:gap-20">
                <p className="text-lg font-bold">Owned: 0</p>
                <p className="text-lg font-bold">Total Supply: 0/200</p>
              </div>

              <div className="lg:leading-8">
                <p>
                  The DragonSlayer NFT is a free NFT a user obtains for
                  collecting all 12 Whelps Dragon NFTs.
                </p>
                <p>
                  Only the War Haven team can mint the Dragon Slayer NFTs and
                  air drop them to you. (To get your Dragon Slayer NFTs open a
                  ticket in our discord server)
                </p>
              </div>

              <div className="flex flex-col gap-5 items-start xl:flex-row xl:gap-6">
                <button
                  className="hover:underline underline-offset-[6px]"
                  onClick={() => {
                    setIsDragonSlayerModalOpen(true);
                  }}
                >
                  Read Dragon Slayer Lore {">"}
                </button>
                <button className="hover:underline underline-offset-[6px]">
                  Dragon Slayer NFT Utility {">"}
                </button>
              </div>
            </div>
          </div>

          <Link to="/slayer-cards">
            <div className="z-30 cursor-pointer relative border border-red p-4 flex flex-col gap-6 lg:flex-row lg:gap-10 lg:p-8 lg:text-lg">
              <img
                src={SlayerCards}
                alt=""
                className="w-full max-w-[25rem] self-center lg:max-w-[21rem] xl:max-w-[19rem]"
              />

              <div className="flex flex-col gap-5">
                <h1 className="font-dragonslapper text-center text-blue text-2xl font-bold lg:text-start">
                  SLAYER CARDS
                </h1>

                <p className="text-lg font-bold">
                  Total Supply: 0/500 (1000 each character)
                </p>

                <div className="lg:leading-8">
                  <p>
                    The Slayer NFT Character Cards Pack is a set of five
                    different cards representing the characters from chapter one
                    of the lore of the Slayer Token Ecosystem.
                  </p>
                  <p>Holders owning all 5 different cards equals one pack.</p>
                </div>

                <div className="flex flex-col gap-5 items-start xl:flex-row xl:gap-6">
                  <button className="hover:underline underline-offset-[6px]" onClick={
                    (e) => {
                      e.preventDefault();
                    }
                  }>
                    Enter the Slayers stronghold {">"}
                  </button>
                  <button className="hover:underline underline-offset-[6px]" onClick={
                    (e) => {
                      e.preventDefault();
                    }
                  }>
                    Slayers Cards Utility {">"}
                  </button>
                </div>
              </div>
            </div>
          </Link>

          <div className="z-30 border border-red p-4 flex flex-col gap-8 lg:p-8">
            <div className="z-30 relative flex flex-col gap-6 lg:flex-row lg:gap-10 lg:text-lg">
              <img
                src={DragonVault}
                alt=""
                className="w-full max-w-[25rem] self-center lg:max-w-[21rem] xl:max-w-[19rem]"
              />

              <div className="flex flex-col gap-5">
                <h1 className="font-dragonslapper text-center text-blue text-2xl font-bold lg:text-start">
                  DRAGON VAULT
                </h1>

                <div className="flex flex-col vsm:flex-row vsm:gap-6 sm:gap-20">
                  <p className="text-lg font-bold">Owned: 0</p>
                  <p className="text-lg font-bold">Total Supply: 0/100</p>
                </div>

                <div className="lg:leading-8">
                  <p>
                    The Dragon Vault NFT is a free NFT a user obtains for
                    collecting all Slayer NFT character cards pack x 10
                  </p>
                  <p>
                    The Dragon Vault NFT is limited to one per wallet for
                    referral link creation. (minted only by War Haven team And
                    air droped by them the user)
                  </p>
                </div>

                <div className="flex flex-col gap-5 items-start xl:flex-row xl:gap-6">
                  <button className="hover:underline underline-offset-[6px]">
                    Read Dragon Vault Lore {">"}
                  </button>
                  <button className="hover:underline underline-offset-[6px]">
                    Dragon Vault NFT Utility {">"}
                  </button>
                </div>
              </div>
            </div>

            <div className="z-30 relative flex flex-col gap-6 lg:flex-row lg:gap-10 lg:text-lg">
              <img
                src={DragonKey}
                alt=""
                className="w-full max-w-[25rem] self-center lg:max-w-[21rem] xl:max-w-[19rem]"
              />

              <div className="flex flex-col gap-5">
                <h1 className="font-dragonslapper text-center text-blue text-2xl font-bold lg:text-start">
                  DRAGON KEY
                </h1>

                <div className="flex flex-col vsm:flex-row vsm:gap-6 sm:gap-20">
                  <p className="text-lg font-bold">Owned: 0</p>
                  <p className="text-lg font-bold">Total Supply: 0/100</p>
                </div>

                <div className="lg:leading-8">
                  <p>
                    The Dragon Key NFT is a free NFT a user obtains for
                    collecting all Slayer Nft character cards pack x 10
                  </p>
                  <p>
                    Only the War Haven team can mint the Dragon Key NFTs and air
                    drop them to you. (To get your Dragon Key NFTs open a ticket
                    in our discord server)
                  </p>
                </div>

                <div className="flex flex-col gap-5 items-start xl:flex-row xl:gap-6">
                  <button className="hover:underline underline-offset-[6px]">
                    Read Dragon Key Lore {">"}
                  </button>
                  <button className="hover:underline underline-offset-[6px]">
                    Dragon Key NFT Utility {">"}
                  </button>
                </div>
              </div>
            </div>

            <div className="z-30 border border-red p-4 flex flex-col gap-4 md:flex-row">
              <div className="bg-white py-2 px-6 text-black text-center flex-1">
                "you are not eligible to create referals"
              </div>

              <button className="bg-[#1a1b1f] py-2 px-4 md:text-lg font-bold">
                Generate Referal
              </button>
            </div>
          </div>

          <div className="relative flex flex-col gap-8">
            {/* Background Image */}
            <img
              src={BackgroundImage}
              alt=""
              className="absolute bottom-0 left-0 w-full h-[160%]"
            />

            <div className="z-30 border border-red p-4 flex flex-col gap-6 lg:p-8">
              <h1 className="font-dragonslapper text-2xl text-blue text-center lg:text-3xl">
                Frequently Asked Questions
              </h1>

              <FAQ question="Question 1" answer="Answer 1" />
              <FAQ question="Question 2" answer="Answer 2" />
              <FAQ question="Question 3" answer="Answer 3" />
              <FAQ question="Question 4" answer="Answer 4" />
            </div>

            <div className="z-30 w-full h-[2px] bg-red mt-5 lg:mt-10"></div>

            <div className="z-30 flex flex-col gap-2 items-center text-white mb-5">
              <p className="font-bold lg:text-lg">
                War Haven 2023 ©️ All rights reserved.
              </p>

              <div className="flex items-center justify-center gap-4">
                <FaDiscord className="w-8 h-8 cursor-pointer" />
                <SiGitbook className="w-8 h-8 cursor-pointer" />
                <FaTwitter className="w-8 h-8 cursor-pointer" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DragonSlayerModal />
    </div>
  );
};

export default MyNFTs;
