import { useAtom } from "jotai";
import React from "react";
import { isLayoutGridAtom } from "../pages/MyNFTs";

const CharacterCard = ({ imageSrc, name, ownedAmount }) => {
  const [isLayoutGrid] = useAtom(isLayoutGridAtom);

  return (
    <div className={`${isLayoutGrid ? "" : "bg-black-lighter flex w-full"}`}>
      <img
        src={imageSrc}
        alt=""
        className={`${isLayoutGrid ? "" : "max-w-[5rem] sm:max-w-[6rem]"}`}
      />

      <div
        className={`bg-black-lighter p-2 sm:p-6 flex ${
          isLayoutGrid
            ? "flex-col gap-2"
            : "flex-1 flex-col md:flex-row md:items-center justify-between"
        }`}
      >
        <h1 className="md:text-lg font-bold md:flex-1">{name}</h1>

        <div className={`${isLayoutGrid ? 'flex flex-col gap-4' : 'flex flex-col gap-2 justify-between sm:flex-row md:items-center md:flex-1'}`}>
          <p className="md:text-lg font-bold">Owned: {ownedAmount}</p>

          <button className="w-fit hover:underline underline-offset-[6px] font-bold md:text-[17px]">
            Read the Lore {">"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CharacterCard;
