import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import MyNFTs from "./pages/MyNFTs";
import SlayerCards from "./pages/SlayerCards";
import WhelpDragons from "./pages/WhelpDragons";
import SlayerNFTMint from "./pages/SlayerNFTMint";

function App() {
  return (
    <div className="bg-bgColor min-h-screen font-courier">
      <Router>
        <Routes>
          <Route path="/" element={<MyNFTs />} />
          <Route path="/slayer-nft-mint" element={<SlayerNFTMint />} />
          <Route path="/slayer-cards" element={<SlayerCards />} />
          <Route path="/whelp-dragons" element={<WhelpDragons />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
