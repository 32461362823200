import React from "react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { SiGitbook } from "react-icons/si";
import DarkWizard from "../assets/Slayer Cards/1. Dark Wizard.jpg";
import GeneralRazor from "../assets/Slayer Cards/2. General Razor.jpg";
import TheEmperor from "../assets/Slayer Cards/3. The Emperor.jpg";
import KingOfTheDragons from "../assets/Slayer Cards/4. King of the Dragons.jpg";
import DragonKnight from "../assets/Slayer Cards/5. Dragon Knight.jpg";
import FAQ from "../components/FAQ";
import Header from "../components/Header";
import IndividualCard from "../components/IndividualCard";

const SlayerNFTMint = () => {
  return (
    <div className="min-h-screen bg-fireBg bg-cover bg-no-repeat bg-bottom relative p-4 lg:p-8 xl:py-8 xl:px-14 2xl:px-20 2xl:py-12">
      <Header />

      <div className="text-white px-1 pt-4 lg:pt-10">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold text-center lg:text-4xl">
              Mint your Slayer card NFTs
            </h1>

            <div className="text-center flex flex-col gap-2">
              <p>
                Here you can mint Slayer character cards either individually or
                mint them as a pack at once which includes all 5 Slayer
                character cards.
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-center gap-6 sm:flex-row sm:gap-8">
            <button className="hover:underline underline-offset-[6px]">
              {"<"} Enter the Slayers Stronghold
            </button>

            <button className="hover:underline underline-offset-[6px]">
              Slayers Cards Utility {">"}
            </button>
          </div>
        </div>

        <div className="border border-red p-5 mt-10 flex flex-col gap-6">
          <div>
            <h1 className="text-blue font-dragonslapper text-center sm:text-start sm:text-2xl">
              SLAYER CHARACTER CARDS
            </h1>
          </div>

          <div className="grid grid-cols-1 gap-4 vsm:grid-cols-2 md:grid-cols-3 md:gap-6 lg:grid-cols-4 2xl:grid-cols-5">
            <IndividualCard
              imageSrc={DarkWizard}
              name="Dark Wizard"
              minted="0"
              price="40"
            />

            <IndividualCard
              imageSrc={GeneralRazor}
              name="General Razor"
              minted="0"
              price="45"
            />

            <IndividualCard
              imageSrc={TheEmperor}
              name="The Emperor"
              minted="0"
              price="50"
            />

            <IndividualCard
              imageSrc={KingOfTheDragons}
              name="King Of The Dragons"
              minted="0"
              price="55"
            />

            <IndividualCard
              imageSrc={DragonKnight}
              name="Dragon Knight"
              minted="0"
              price="60"
            />
          </div>
        </div>

        <div className="z-30 mt-10 border border-red p-4 flex flex-col gap-4 md:p-6 lg:p-8">
          <h1 className="font-dragonslapper text-2xl text-center text-blue md:hidden font-bold">
            Slayer Card Packs
          </h1>

          <div className="grid gap-4 md:grid-cols-2 md:gap-10 lg:grid-cols-3">
            <div className="flex flex-col gap-2 md:flex-1">
              <img
                src={DarkWizard}
                alt=""
                className="w-full max-w-[25rem] mb-2 self-center md:max-w-none"
              />

              <p className="md:text-lg font-bold">
                Minted Slayer Card packs: 0/50
              </p>

              <p className="md:text-lg font-bold">
                Slayer Card pack price:{" "}
                <span className="text-blue">250 USDC</span>
              </p>

              <div className="flex flex-col gap-4 my-2">
                <div className="grid grid-cols-4 gap-x-2">
                  <button className="bg-red text-[17px] font-bold py-3 rounded-lg">
                    -
                  </button>
                  <div className="col-span-2 text-[17px] font-bold bg-white py-3 rounded-lg text-black text-center">
                    0
                  </div>
                  <button className="bg-red text-[17px] font-bold py-3 rounded-lg">
                    +
                  </button>
                </div>

                <button className="bg-red py-3 text-[17px] rounded-lg font-bold">
                  Mint
                </button>
              </div>
            </div>

            <div className="text-[17px] font-bold flex flex-col gap-6 md:flex-1 md:text-[19px] md:leading-10 lg:col-span-2">
              <h1 className="font-dragonslapper hidden text-2xl text-center text-blue md:block md:text-start md:text-3xl font-bold">
                Slayer Card Packs
              </h1>

              <div>
                <p>
                  Minting SLayer Card through packs has the following discounts:
                </p>

                <p>
                  - Only users who purchase Slayer Card packs qualify for
                  discount.
                </p>

                <p>
                  - Users who have Slayer NFT WL role will have 40% off the full
                  $250 price.
                </p>

                <p>
                  - Owning each of the 12 Whelp Dragon NFTs gives an additional
                  1% discount.
                </p>

                <p>
                  - Maximum of <span className="text-blue">52%</span> discount ={" "}
                  <span className="text-blue">Slayer NFT WL role 40%</span> +{" "}
                  <span className="text-blue">12 Whelp Dragon 12%</span>
                </p>

                <p>
                  Note: Any discounts users get will be done in War Haven
                  Discord server over tickey by rebate.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative flex flex-col gap-8 mt-10">
          <div className="z-30 border border-red p-4 flex flex-col gap-6 lg:p-8">
            <h1 className="font-dragonslapper text-2xl text-blue text-center lg:text-3xl">
              Frequently Asked Questions
            </h1>

            <FAQ question="Question 1" answer="Answer 1" />
            <FAQ question="Question 2" answer="Answer 2" />
            <FAQ question="Question 3" answer="Answer 3" />
            <FAQ question="Question 4" answer="Answer 4" />
          </div>

          <div className="z-30 w-full h-[2px] bg-red mt-5 lg:mt-10"></div>

          <div className="z-30 flex flex-col gap-2 items-center text-white mb-5">
            <p className="font-bold lg:text-lg">
              War Haven 2023 ©️ All rights reserved.
            </p>

            <div className="flex items-center justify-center gap-4">
              <FaDiscord className="w-8 h-8 cursor-pointer" />
              <SiGitbook className="w-8 h-8 cursor-pointer" />
              <FaTwitter className="w-8 h-8 cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlayerNFTMint;
