import { atom, useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { SiGitbook } from "react-icons/si";
import { Link } from "react-router-dom";
import WarHavenLogo from "../assets/War_Haven logo.png";

export const activeTabAtom = atom("myNFTs");

const Header = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [activeTab, setActiveTab] = useAtom(activeTabAtom);

  const sidebarRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleButtonClick = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  return (
    <div ref={sidebarRef} className="relative text-white z-50">
      <button className="text-white lg:hidden" onClick={handleButtonClick}>
        <HiMenu className="w-8 h-8" />
      </button>

      <div
        className={`${
          isSidebarVisible ? "translate-x-0" : "-translate-x-full"
        } fixed top-0 left-0 h-full w-full bg-black-lighter p-6 transition-transform ease-in-out duration-300 z-10 lg:bg-[transparent] lg:translate-x-0 lg:static lg:p-0`}
      >
        <button
          className="absolute z-30 top-4 right-4 text-white focus:outline-none lg:hidden"
          onClick={handleButtonClick}
        >
          <IoClose className="w-8 h-8" />
        </button>

        <div className="pt-14 pb-7 h-full flex flex-col items-center justify-between lg:py-0 lg:block">
          <div className="flex flex-col items-center gap-10 lg:flex-row lg:justify-between">
            <Link
              to="/"
              onClick={() => {
                setActiveTab("myNFTs");
              }}
            >
              <img
                src={WarHavenLogo}
                alt=""
                className="w-[12rem] lg:w-[9rem]"
              />
            </Link>

            <nav>
              <ul className="flex flex-col gap-6 items-center text-xl font-bold lg:text-lg lg:flex-row lg:gap-8">
                <Link to="/">
                  <li
                    className={`order-2 cursor-pointer underline-offset-[6px] ${
                      activeTab === "myNFTs"
                        ? "text-red underline decoration-red-lighter"
                        : "transition-all duration-200 ease-out decoration-red-lighter hover:text-red-lighter hover:underline"
                    }`}
                    onClick={() => {
                      setActiveTab("myNFTs");
                    }}
                  >
                    My NFTs
                  </li>
                </Link>

                <Link to="/slayer-nft-mint">
                  <li
                    className={`order-2 cursor-pointer underline-offset-[6px] ${
                      activeTab === "slayerCards"
                        ? "text-red underline decoration-red-lighter"
                        : "transition-all duration-200 ease-out decoration-red-lighter hover:text-red-lighter hover:underline"
                    }`}
                    onClick={() => {
                      setActiveTab("slayerCards");
                    }}
                  >
                    Slayer NFT MINT
                  </li>
                </Link>

                <li className="order-1 lg:order-3">
                  <button className="bg-[#1a1b1f] py-2 px-6">
                    Connect Wallet
                  </button>
                </li>
              </ul>
            </nav>
          </div>

          <div className="flex items-center justify-center gap-4 lg:hidden">
            <FaDiscord className="w-8 h-8 cursor-pointer" />
            <SiGitbook className="w-8 h-8 cursor-pointer" />
            <FaTwitter className="w-8 h-8 cursor-pointer" />
          </div>
        </div>

        <div className="hidden lg:block w-full h-[2px] bg-red mt-4"></div>
      </div>
    </div>
  );
};

export default Header;
