import { useAtom } from "jotai";
import React from "react";
import DragonSlayer from "../assets/Dragonslayer.jpg";
import { isDragonSlayerModalOpenAtom } from "../pages/MyNFTs";

const DragonSlayerModal = () => {
  const [isDragonSlayerModalOpen, setIsDragonSlayerModalOpen] = useAtom(
    isDragonSlayerModalOpenAtom
  );

  return (
    <div
      className={`bg-[#000000d6] z-40 w-full h-full fixed top-0 left-0 overflow-y-auto text-white transition-all duration-200 ease-linear p-5 lg:p-8 xl:py-8 xl:px-14 ${
        isDragonSlayerModalOpen
          ? "opacity-100"
          : "opacity-0 pointer-events-none"
      }`}
    >
      <div className="min-w-full min-h-full flex items-center">
        <div className="bg-fireBg bg-no-repeat bg-bottom bg-cover border border-red p-4 flex flex-col gap-6 lg:flex-row lg:gap-10 lg:p-8 lg:text-lg">
          <div className="max-w-[25rem] self-center lg:min-w-[22rem] lg:max-w-[22rem]">
            <img src={DragonSlayer} alt="" />

            <div className="bg-black-lighter p-6 flex flex-col gap-4 text-center">
              <h1 className="font-dragonslapper text-xl">DRAGON SLAYER</h1>
              <p className="font-bold">
                Attack: <span className="text-red">+999</span> | Defense:{" "}
                <span className="text-red">+999</span>
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-4 lg:gap-6">
            <h1 className="text-center text-lg font-bold lg:text-start">
              Dragon Slayer, The Elites Of The Resistane
            </h1>

            <div className="flex flex-col gap-2 lg:gap-4">
              <p>
                <span className="underline">Effect 1:</span> Special Summoned
                only when a player has the following Five ards in Hand: "Lieran,
                The Dark Wizard", Theophilus Reitus IV, "Emperor of the Unnadek
                Empire", "Xtra'jaa, Destroyer Of Man", and "Dragon Knight"
              </p>

              <p>
                <span className="underline">Effect 2:</span> While Summoned
                Opponent is unable to make sacrifies at all.
              </p>

              <p>
                <span className="underline">Effect 3:</span> This card cannot be
                destroyed in battle. This is not Effect so mych as rule change
                upon Summoning and so is not negated by effects that negate
                other effects.
              </p>
            </div>

            <button
              className="text-lg font-bold underline underline-offset-4 my-4 lg:text-xl"
              onClick={() => {
                setIsDragonSlayerModalOpen(false);
              }}
            >
              Close Window
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DragonSlayerModal;
