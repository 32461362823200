import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

const FAQ = (props) => {
  const [isFaqOpen, setIsFaqOpen] = useState(false);

  return (
    <div className="border border-red">
      <div
        className="bg-black-lighter cursor-pointer flex items-center gap-4 justify-between p-3 lg:py-5 lg:px-6"
        onClick={() => setIsFaqOpen((prev) => !prev)}
      >
        <p className="font-bold">{props.question}</p>
        <FaChevronDown className="text-red text-xl" />
      </div>

      <div
        className={`bg-white text-black p-3 lg:py-5 lg:px-6 ${
          isFaqOpen ? "block" : "hidden"
        }`}
      >
        <p className="font-bold">{props.answer}</p>
      </div>
    </div>
  );
};

export default FAQ;
